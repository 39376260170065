<template>
  <div class="home">
    <h1>Components</h1>
    <h2>Lottie</h2>
  <chefLottie/>
  </div>
</template>

<script>
import ChefLottie from '../components/ChefLottie.vue';

export default {
  components: {
    ChefLottie,
  },
};
</script>

<style lang="stylus">
  .home
    max-width: (1000/16)rem
    width: 100%
    margin: 200px auto

</style>
